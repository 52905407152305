import { useTheme } from '@emotion/react';

import { useBlogArchive } from '../BlogArchiveContext';

import { normalizeRichTextImageContent, richTextWithPlaceholder } from 'utils/prismic';

export const useInfoData = () => {
  const { title, description } = useBlogArchive().blogArchive.data;
  const { colors } = useTheme();

  return {
    title: title ?? 'Blog',
    description: normalizeRichTextImageContent(richTextWithPlaceholder(description, ''), {
      keyBuilder: index => `${title}-${index}`,
      altBuilder: () => title,
      placeholderColor: colors.lighterGray,
    }),
  };
};
