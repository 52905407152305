import React from 'react';
import { graphql, PageProps } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { withBlogArchiveProvider } from './BlogArchiveContext';
import InfoSection from './InfoSection';
import ArticlesSection from './ArticlesSection';
import { useBlogArchiveData } from './hooks';

import { ContainedLayout } from 'components/Layout';

const ArchiveLayout = styled.article`
  ${({ theme: { mq } }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    padding: 60px 0 180px;

    ${mq.md} {
      row-gap: 32px;
      padding-bottom: 120px;
    }
  `}
`;

const BlogArchive: React.FC<Omit<PageProps, 'data'>> = () => {
  const { layoutProps } = useBlogArchiveData();

  return (
    <ContainedLayout {...layoutProps}>
      <ArchiveLayout>
        <InfoSection />
        <ArticlesSection />
      </ArchiveLayout>
    </ContainedLayout>
  );
};

export const blogArchiveFragment = graphql`
  fragment BlogArchiveData on PrismicBlogArchive {
    ...BlogArchiveInfoData

    lang
    alternate_languages {
      document {
        ... on PrismicBlogArchive {
          lang
          url
        }
      }
    }
  }
`;

export default withBlogArchiveProvider(BlogArchive);
