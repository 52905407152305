import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';

import { useBlogArchive } from '../BlogArchiveContext';

const ALL_TAG = { name: 'All', uid: '-1' };

const useNormalizedArticlesData = () => {
  const { nodes, group } = useBlogArchive().blogArticles;

  const articlesByUid = useMemo(
    () =>
      nodes.reduce(
        (acm, article) => ({ ...acm, [article.uid]: article }),
        {} as Record<string, Queries.BlogArticleThumbnailDataFragment>
      ),
    [nodes]
  );

  const articleUidsByTag = useMemo(
    () =>
      group.reduce(
        (acm, { fieldValue: tag, nodes }) => {
          const articleUids = nodes.map(({ uid }) => uid);
          return { ...acm, [tag]: articleUids };
        },
        { [ALL_TAG.uid]: nodes.map(({ uid }) => uid) } as Record<string, string[]>
      ),
    [nodes, group]
  );

  const sortedTags = useMemo(() => {
    const sortedTagsByArticlesCount = [...group]
      .sort(({ totalCount: a }, { totalCount: b }) => b - a)
      .map(({ fieldValue: tag }) => ({ name: tag, uid: tag }));

    return [ALL_TAG, ...sortedTagsByArticlesCount];
  }, [group]);

  return { articlesByUid, articleUidsByTag, sortedTags };
};

export const useArticlesData = () => {
  const [currentTag, setCurrentTag] = useState<string>(ALL_TAG.uid);
  const [currentTagForThumbnails, setCurrentTagForThumbnails] = useState<string>(currentTag);
  const [showThumbnails, setShowThumbnails] = useState(true);

  const { durations } = useTheme();
  const transitionDuration = durations.regular;
  const transitionDurationNumber = Number(transitionDuration.slice(0, -2));

  const { articlesByUid, articleUidsByTag, sortedTags } = useNormalizedArticlesData();

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setCurrentTagForThumbnails(currentTag);
      setShowThumbnails(true);
    }, transitionDurationNumber);

    return () => {
      clearTimeout(animationTimeout);
    };
  }, [currentTag]);

  const categoriesData = useMemo(() => {
    return sortedTags.map(({ name, uid }) => ({
      name,
      isActive: uid === currentTag,
      handleClick: () => {
        if (uid !== currentTag) {
          setShowThumbnails(false);
          setCurrentTag(uid);
        }
      },
    }));
  }, [sortedTags, currentTag]);

  const thumbnailsData = useMemo(
    () => articleUidsByTag[currentTagForThumbnails].map(uid => articlesByUid[uid]),
    [currentTagForThumbnails, articleUidsByTag, articlesByUid]
  );

  return {
    categoriesData,
    thumbnailsData,
    showThumbnails,
    transitionDuration,
  };
};
