import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import BlogArchive from './BlogArchive';

import { withHead } from 'components/SEO';

export const Head = withHead<Queries.BlogArchiveQuery>('blogArchive');

export const query = graphql`
  query BlogArchive($id: String, $lang: String) {
    blogArchive: prismicBlogArchive(id: { eq: $id }) {
      _previewable
      ...SEOData
      ...BlogArchiveData
    }

    blogArticles: allPrismicBlogArticle(filter: { lang: { eq: $lang } }) {
      ...BlogArchiveArticlesData
    }
  }
`;

export type AllBlogArchivesQuery = Queries.AllBlogArchivesQuery;

export default withPrismicPreview(BlogArchive);
